<template>
    <div>
        <div style="margin-bottom: 5px;">
                <el-input v-model="name" placeholder="请输入物品名" suffix-icon="el-icon-search" style="width: 200px;"
                          @keyup.enter.native="loadPost"></el-input>
    
                <el-button type="primary" style="margin-left: 5px;" @click="searchGet">查询</el-button>
                <el-button type="add" @click="add">添加</el-button>
        </div>
        <el-table :data="tableData"
                  :header-cell-style="{ background: '#f2f5fc', color: '#555555' }"
                  border
                  highlight-current-row
                  @current-change="selectCurrentChange"
        >
            <el-table-column prop="id" label="ID" width="60">
            </el-table-column>
            <el-table-column prop="name" label="商品名称" width="80">
            </el-table-column>
            <el-table-column prop="image" label="商品图片" width="140" >
            </el-table-column>
            <el-table-column prop="goodstype" label="分类" width="100" :formatter="formatGoodstype">
            </el-table-column>
            <el-table-column prop="quantity" label="数量" width="80">
            </el-table-column>
            <el-table-column prop="price" label="价格" width="80">
            </el-table-column>

            <el-table-column prop="createTime" label="添加日期" width="120">
                <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
                </template>
            </el-table-column>

            <el-table-column prop="updateTime" label="修改日期" width="120">
                <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span style="margin-left: 10px">{{ scope.row.updateTime }}</span>
                </template>
            </el-table-column>

            <el-table-column prop="remark" label="备注" width="240">
            </el-table-column>

            <el-table-column label="操作">
            <template slot-scope="scope">
                <el-button
                size="mini"
                @click="mod(scope.row)">编辑</el-button>
                <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
            </el-table-column>
        </el-table>

        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNum"
                :page-sizes="[5, 10, 20,30]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>

        <!-- 编辑添加弹框 -->
        <el-dialog
                title="商品管理"
                :visible.sync="centerDialogVisible"
                width="30%"
                center>

            <el-form ref="form"  :model="form" label-width="80px">

                <el-form-item label="商品名称" prop="name">
                    <el-col :span="20">
                        <el-input v-model="form.name"></el-input>
                    </el-col>
                </el-form-item>
                
                <el-form-item label="商品图片" prop="image">
                    <el-col :span="20">
                        <el-input v-model="form.image"></el-input>
                    </el-col>
                </el-form-item>

                <el-form-item label="分类" prop="goodstype">
                    <el-col :span="20">
                        <el-select v-model="form.goodstype" placeholder="请选择分类" style="margin-left: 5px;">
                            <el-option
                                    v-for="item in goodstypeData"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-col>
                </el-form-item>

                <el-form-item label="数量" prop="quantity">
                    <el-col :span="20">
                        <el-input v-model="form.quantity"></el-input>
                    </el-col>
                </el-form-item>

                <el-form-item label="价格" prop="price">
                    <el-col :span="20">
                        <el-input v-model="form.price"></el-input>
                    </el-col>
                </el-form-item>

                <el-form-item label="备注" prop="remark">
                    <el-col :span="20">
                        <el-input type="textarea" v-model="form.remark"></el-input>
                    </el-col>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
  export default {
    name: "PriceManage",
    data() {
      return {
        storageData:[],
        goodstypeData:[],
        tableData: [],
        centerDialogVisible: false,
        form:{
        id:'',
        name:'',
        image: '',
        price:'',
        goodstype:'',
        quantity:'',
        remark:''
      },
      pageNum: 1,
      pageSize: 10,
      total: 0,
      name: '',
      }
    },
    methods: {
      resetForm() {
        this.$refs.form.resetFields();
      },
      resetInForm(){
        this.$refs.form1.resetFields();
      },
      formatStorage(row){
                let temp =  this.storageData.find(item=>{
                    return item.id == row.storage
                })

                return temp && temp.name
            },
      formatGoodstype(row){
                let temp =  this.goodstypeData.find(item=>{
                    return item.id == row.goodstype
                })

                return temp && temp.name
            },
      handleEdit(index, row) {
        console.log(index, row);
      },
      selectCurrentChange(val) {
                this.currentRow = val;
       },
      handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
                this.pageNum=val
                this.loadPost()
      },
      handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
                this.pageNum=1
                this.pageSize=val
                this.loadPost()
            },
      // 修改商品
      mod(row){
        console.log("修改商品row.id:"+row.id);
        console.log("修改商品row.name:"+row.name);
        console.log("修改商品row.image:"+row.image);
        console.log("修改商品row.price:"+row.price);
        console.log("修改商品row.goodstype:"+row.goodstype);
        console.log("修改商品row.quantity"+row.quantity);
        console.log("修改商品row.remark:"+row.remark);
        this.centerDialogVisible = true;
        this.$nextTick(()=>{
        this.form.id = row.id;
        this.form.name = row.name;
        this.form.image = row.image;
        this.form.price = row.price;
        this.form.goodstype = row.goodstype;
        this.form.quantity = row.quantity;
        this.form.remark = row.remark;
        })
        },
        // 添加商品
      add(){
        this.centerDialogVisible = true
        this.$nextTick(()=>{
            this.resetForm()
            this.form.id = ''
        
            })
        },
        searchGet(){
                const params = {
                page: this.pageNum,
                size: this.pageSize,
            };

            // 如果有商品名称，则添加到参数中
            if (this.name) {
                params.name = this.name;
            }

            this.$axios.get(`${this.$httpUrl}/product/search`, { params })
                .then(res => {
                    if (res && res.data) {
                        if (res.data.code === 200) {
                            this.tableData = res.data.data;
                            this.total = res.data.total;
                        } else {
                            this.$message.error('获取数据失败: ' + (res.data.msg || '未知错误'));
                        }
                    } else {
                        this.$message.error('获取数据失败: 响应无效');
                    }
                })
                .catch(error => {
                    console.error('请求失败:', error);
                    this.$message.error('请求失败: ' + (error.message || '未知错误'));
                });
            },
        // 加载商品列表
        loadPost() {
            this.$axios.get(`${this.$httpUrl}/product/list`, {
                params: {
                    page: this.pageNum,
                    size: this.pageSize
                }
            }).then(res => {
                if (res.data.code === 200) {
                    this.tableData = res.data.data;
                    this.total = res.data.total;
                } else {
                    alert('获取数据失败');
                }
            });
        },
        //根据goodstype获取分类名称
        getGoodstype(){
            this.$axios.get(`${this.$httpUrl}/goodstype/list`)
            .then(res=>{
                if(res.data.code==200){
                    this.goodstypeData=res.data.data
                }else{
                    alert('获取数据失败')
                }
            })
        },
        // 删除商品
        handleDelete(index, row) {
            this.$axios.delete(`${this.$httpUrl}/product/delete/${row.id}`)
                .then(res => {
                    if (res.data.code === 200) {
                        this.tableData.splice(index, 1);
                        this.loadPost(); // 重新加载数据
                    } else {
                        alert('删除失败');
                    }
                });
        },
        save() {
       //校验表单
    this.$refs['form'].validate((valid) => {
      console.log("表单数据valid："+valid);
      console.log("表单数据id："+this.form.id);
      console.log("表单数据name："+this.form.name);
      console.log("表单数据images："+this.form.image);
      console.log("表单数据quantity："+this.form.quantity);
      console.log("表单数据goodstype："+this.form.goodstype);
      console.log("name"+this.form.name);
      console.log("表单数据remark："+this.form.remark);
      if (valid) {
        // 检查是否为更新操作
        if (this.form.id) {
          this.$axios.put(this.$httpUrl+'/product/update', this.form).then(res => 
          {
            if (res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '修改成功!'
              });
              this.centerDialogVisible = false;
              this.loadPost();
            } else {
              this.$message({
                type: 'error',
                message: '修改失败!'
              });
            }
          }).catch(error => {
            this.$message({
              type: 'error',
              message: '请求失败!'
            });
            console.error(error);
          });
        } else {
          this.$axios.post(this.$httpUrl+'/product/add', this.form).then(res => 
          {
            if (res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '添加成功!'
              });
              this.centerDialogVisible = false;
              this.loadPost();
            } else {
              this.$message({
                type: 'error',
                message: '添加失败!'
              });
            }
          }).catch(error => {
            this.$message({
              type: 'error',
              message: '请求失败!'
            });
            console.error(error);
          });
        }
      } else {
        this.$message({
          type: 'error',
          message: '请填写必填项!'
        });
      }
    });
  },
   loadGoodstype(){
           // 发送GET请求获取商品类型列表
           this.$axios.get(this.$httpUrl+'/goodstype/list').then(res=>res.data).then(res=>{
               console.log(res)
          // 检查响应代码，如果为200，则表示请求成功
          if(res.code==200){
              // 将获取到的商品类型数据存储在goodstypeData属性中
              this.goodstypeData=res.data
          }else{
              // 如果请求失败，弹窗提示用户
              alert('获取数据失败')
          }
      })
        },
    },
    beforeMount() {
      this.getGoodstype()
      this.loadPost()
      this.loadGoodstype()
        }
  }
</script>
